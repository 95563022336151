<template>
  <div>
    <el-card style="width: 100pc">
      <el-form :model="orderFormData" :label-position="orderLabelPosition" inline size="small">
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName">
          <el-input v-model="orderFormData.clientName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner">
          <el-input v-model="orderFormData.l3Owner" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark">
          <el-input v-model="orderFormData.pageRemark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.payTerm')" prop="payTerm">
          <el-input v-model="orderFormData.payTerm" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.account.dueDate')" prop="dueDate" >
<!--          <el-date-picker :v-model="orderFormData.dueDate" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>-->
          <el-input v-model="orderFormData.dueDate" style="width: 160px"></el-input>
        </el-form-item>
      </el-form>
      <el-form :data="drayOrder" :label-position="orderLabelPosition" inline size="small">
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')">
          <el-input v-model="drayOrder.streetOne"></el-input>
        </el-form-item>
        <el-form-item prop="streetTwo" :label="$t('msg.address.streetTwo')">
          <el-input v-model="drayOrder.streetTwo"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')">
          <el-input v-model="drayOrder.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')">
          <el-input v-model="drayOrder.state"></el-input>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')">
          <el-input v-model="drayOrder.zip"></el-input>
        </el-form-item>
      </el-form>
    <el-divider></el-divider>
      <template #header>
        <span>{{$t('msg.route.invoice')}}</span>
        <span style="float: left;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalAR}}</span>
        <el-button type="primary" size="small" style="float: right; margin-bottom: 30px" @click="addAP('AR')">
          {{ $t('msg.basicInfo.addRecord') }}
        </el-button>
      </template>
      <el-table :data="interimAR" style="font-size: smaller">
        <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.remark" placeholder="Cost Category" size="small" Style="font-size: small" @change="getItem(scope.$index,'AR')" v-if="scope.$index+arFlag===-1">
              <el-option label="Pass Through" value="Pass Through"></el-option>
              <el-option label="Std Ad Hoc" value="Std Ad Hoc"></el-option>
              <el-option label="Other" value="Other"></el-option>
            </el-select>
            <span v-else>{{scope.row.remark}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="item" :label="$t('msg.rate.item')" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.item" size="small" @change="handleItemChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1 && scope.row.remark!=='Other'">
              <el-option v-for="item in billTemp" :key="item.item" :label="item.item" :value="item.item" />
            </el-select>
            <el-input v-model="scope.row.notes" size="small" Style="font-size: small" v-else-if="scope.$index+arFlag===-1 && scope.row.remark==='Other'"></el-input>
            <span v-else>{{scope.row.item}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.qty" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1"></el-input-number>
            <span v-else>{{scope.row.qty}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.rate" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1 && scope.row.remark==='Other'"></el-input-number>
            <span v-else>$ {{scope.row.rate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.finalAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('msg.rate.Operations')" align="center">
          <template #default="scope">
            <el-button type="success" size="small" @click="addBillItem(scope.$index,'AR')" plain v-if="scope.$index+arFlag===-1">Confirm</el-button>
            <el-button type="danger" size="small" @click="cancelUpdateAP('AR')" plain v-if="scope.$index+arFlag===-1">Cancel</el-button>
            <el-button type="primary" size="small" @click="editAP(scope.$index,'AR')" v-if="scope.$index+arFlag!==-1">Edit</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-divider></el-divider>
      <el-button type="primary" size="small" @click="submitUpdate" disabled>Submit Update</el-button>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import { getSum } from '@/utils/billEditTempOp'

export default defineComponent({
  name: 'billEditTemp',
  props: {
    myParam: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const arFlag = ref(0)
    const totalAR = ref(0)
    const standardParam = ref('')
    const allDrayRates = ref([])
    const billTemp = ref([{ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 }])
    const orderLabelPosition = ref('top')
    const drayOrder = ref({ id: 0, sslCarrier: '', mbl: '', ctnrId: '', ctnrSize: '', sealNumber: 'NA', vesselETA: '', ctnrWeight: 0, wtUOM: 'lb', port: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', attr: '' })
    const orderFormData = ref({ clientName: '', l3Owner: '', pageRemark: 'NA', payTerm: '', dueDate: '', clientTag: '' })
    const interimAR = ref()
    const myOrderRecord = ref()
    const getInterimAP = (billType) => {
      const param = standardParam.value + '&billType=' + billType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getInterimAPAR'
      axios.post(reqURL, param).then((response) => {
        interimAR.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getDrayRateAR = () => {
      const param = standardParam.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDrayRateAR'
      axios.post(reqURL, param).then((response) => {
        billTemp.value = response.data
        localStorage.setItem('myDrayRates', JSON.stringify(response.data))
      }).catch((response) => {
        console.log(response)
      })
    }
    const addAP = (billType) => {
      if (billType === 'AR' && arFlag.value !== 0) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      getDrayRateAR()
      interimAR.value.push({ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
      arFlag.value = -(interimAR.value.length)
    }
    const editAP = (id, billType) => {
      if (billType === 'AR' && arFlag.value !== 0) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      getInterimAP(billType)
      getDrayRateAR()
      arFlag.value = -(id + 1)
    }
    const cancelUpdateAP = (billType) => {
      arFlag.value = 0
      getInterimAP(billType)
    }
    const getItem = (id, billType) => {
      allDrayRates.value = JSON.parse(localStorage.getItem('myDrayRates'))
      const list = interimAR.value
      const selectedRemark = []
      const myRemark = list[id].remark
      if (myRemark === 'Other') {
        selectedRemark.push({ item: '', remark: 'Other', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
      } else {
        for (let n = 0; n < allDrayRates.value.length; n++) {
          if (allDrayRates.value[n].remark === myRemark) {
            selectedRemark.push(allDrayRates.value[n])
          }
        }
      }
      billTemp.value = selectedRemark
      list[id] = selectedRemark[0]
    }
    const handleItemChange = (id, billType) => {
      allDrayRates.value = JSON.parse(localStorage.getItem('myDrayRates'))
      const list = interimAR.value
      for (let n = 0; n < allDrayRates.value.length; n++) {
        if (allDrayRates.value[n].item === list[id].item) {
          list[id] = allDrayRates.value[n]
          return
        }
      }
    }
    const qtyChange = (id, billType) => {
      interimAR.value[id].finalAmount = interimAR.value[id].qty * interimAR.value[id].rate
    }
    const addBillItem = (id, billType) => {
      arFlag.value = 0
      const list = interimAR.value
      const param = standardParam.value + '&billTemp=' + JSON.stringify(list[id]) + '&addToAR=' + billType
      const reqURL = process.env.VUE_APP_BASE + '/dray/addBillItem'
      axios.post(reqURL, param).then((response) => {
        alert(response.data)
        getInterimAP('AR')
        totalAR.value = getSum(interimAR.value)
      }).catch((response) => {
        console.log(response)
      })
    }
    const submitUpdate = () => {
      console.log('submitUpdate')
    }
    onMounted(() => {
      myOrderRecord.value = JSON.parse(sessionStorage.getItem('curOrderRecord'))
      orderFormData.value.l3Owner = myOrderRecord.value.serviceProvider
      orderFormData.value.clientName = myOrderRecord.value.client
      orderFormData.value.payTerm = myOrderRecord.value.payTerm.split('--')[0]
      orderFormData.value.dueDate = myOrderRecord.value.payTerm.split('--')[1]
      const param = props.myParam
      const reqURL = process.env.VUE_APP_BASE + '/dray/getInvoiceToEdit'
      console.log(orderFormData.value.dueDate)
      axios.post(reqURL, param).then((response) => {
        drayOrder.value = response.data.drayOrder
        interimAR.value = response.data.interimAR
        standardParam.value = param
        totalAR.value = getSum(interimAR.value)
      }).catch((response) => {
        console.log(response)
      })
    })
    return { billTemp, allDrayRates, standardParam, arFlag, totalAR, myOrderRecord, orderLabelPosition, drayOrder, orderFormData, interimAR, props, submitUpdate, addAP, editAP, cancelUpdateAP, getItem, handleItemChange, qtyChange, getDrayRateAR, addBillItem }
  }
})
</script>
